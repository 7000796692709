@tailwind base;
@tailwind components;
@tailwind utilities;

.circular-checkbox input[type="checkbox"] {
  display: none;
}

.circular-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

.circular-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #144d33;
}

.circular-checkbox input[type="checkbox"]:checked + .checkmark::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #144d33;
  font-size: 14px;
  font-weight: bold;
}
.circular-checkbox-inactive input[type="checkbox"] {
  display: none;
}

.circular-checkbox-inactive {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  color: #0e0e0e;
  opacity: 0.4;
}

.circular-checkbox-inactive .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid grey;
}

.circular-checkbox-inactive
  input[type="checkbox"]:checked
  + .checkmark::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey;
  font-size: 14px;
  font-weight: bold;
}

.checkAccount {
  position: absolute;
  top: 5px;
  right: 10px;
}

.input {
  width: 60px;
  border-radius: 10px;
  border: none;
  border: 2px solid #f5f6f6;
  margin-right: 10px;
  text-align: center;
  font-size: 36px;
  cursor: not-allowed;
  pointer-events: none;
}

.input:focus {
  border-bottom: 3px solid orange;
  outline: none;
}

.input:nth-child(1) {
  cursor: pointer;
  pointer-events: all;
}

.scrollbar-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.scrollable-form-container {
  max-height: 80vh;
  overflow-y: auto;
}
